import api from "./api"

export default {
    async index() {
        return api.get("wh-api/packing/index")
    },
    async packingList(batchId, amount) {
        return api.post("wh-api/packing-list", {
            batchId: batchId,
            amount: amount
        })
    },
    async setInTransit() {
        return api.put("wh-api/packing/set-in-transit")
    },
}
