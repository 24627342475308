import order from "@/api/order"
import {EventBus} from "@/event-bus";

export default {
    reloadOrder(id) {
        return new Promise((resolve, reject) => {
            order.show(id).then(response => {
                if (response.status === 200) {
                    let order = response.data.data
                    EventBus.$emit('orderReloaded', order)
                    resolve(order)
                } else {
                    console.error(response)
                    reject()
                }
            })
                .catch(function (error) {
                    console.error(error)
                    reject()
                })
        })
    }
}