<template>
  <div class="flow-root">
    <ul class="-mb-8">
      <li v-for="(eventItem, eventItemIdx) in events" :key="eventItem.id">
        <div class="relative pb-8">
          <span v-if="(eventItemIdx !== events.length - 1)" class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
          <div class="relative flex items-start space-x-3">
            <template v-if="eventItem.type === 3">
              <div class="relative">
                <div class="h-10 w-10 rounded-full bg-gray-100 flex items-center justify-center ring-8 ring-white">
                  <UserCircleIcon class="h-8 w-8 text-gray-500" aria-hidden="true" />
                </div>

                <span class="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                  <ChatAltIcon class="h-5 w-5 text-blue-500" aria-hidden="true" />
                </span>
              </div>
              <div class="min-w-0 flex-1">
                <div>
                  <div class="text-sm">
                    <span class="font-medium text-gray-900">{{ eventItem.admin ? eventItem.admin.name : 'Rendszer' }}</span>
                  </div>
                  <p class="mt-0.5 text-sm text-gray-500">Megjegyzés {{ eventItem.date_formatted }}</p>
                </div>
                <div class="mt-2 text-sm text-gray-700">
                  <p>
                    {{ eventItem.message }}
                  </p>
                </div>
              </div>
            </template>
            <template v-else-if="eventItem.type === 2">
              <div>
                <div class="relative px-1">
                  <div class="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                    <TagIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                  </div>
                </div>
              </div>
              <div class="min-w-0 flex-1 py-0">
                <div class="text-sm text-gray-500">
                  <div>
                    <span class="font-medium text-gray-900">{{ eventItem.admin ? eventItem.admin.name : 'Rendszer' }}</span>
                    {{ ' ' }}
                    <span class="whitespace-nowrap">{{ eventItem.date_formatted }}</span>
                  </div>
                  {{ eventItem.event }}
                  <span class="mr-0.5">
                      <span class="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                        <span class="absolute flex-shrink-0 flex items-center justify-center">
                          <span class="bg-blue-500 h-1.5 w-1.5 rounded-full" aria-hidden="true" />
                        </span>
                        <span class="ml-3.5 font-medium text-gray-900">{{ eventItem.message }}</span>
                      </span>
                  </span>
                </div>
              </div>
            </template>
            <template v-else>
              <div>
                <div class="relative px-1">
                  <div class="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                    <component :is="getIconComponent(eventItem.type)" :class="['h-5 w-5', getIconColor(eventItem.type)]" aria-hidden="true" />
                  </div>
                </div>
              </div>
              <div class="min-w-0 flex-1 py-1.5">
                <div class="text-sm text-gray-500">
                  <span class="font-medium text-gray-900">{{ eventItem.admin ? eventItem.admin.name : 'Rendszer' }}</span>
                  {{ ' ' }}
                  <span class="whitespace-nowrap">{{ eventItem.date_formatted }}</span>
                  <div class="">{{ eventItem.event }} - {{ eventItem.message }}</div>
                  <div v-if="eventItem.data" class="font-mono">{{ JSON.stringify(eventItem.data, null, 4) }}</div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ChatAltIcon, TagIcon, UserCircleIcon, AtSymbolIcon, ExclamationIcon, InformationCircleIcon, ClipboardListIcon, TruckIcon } from '@heroicons/vue/solid'

export default {
  components: {
    ChatAltIcon,
    TagIcon,
    UserCircleIcon,
    AtSymbolIcon,
    ExclamationIcon,
    InformationCircleIcon,
    ClipboardListIcon,
    TruckIcon
  },
  props: {
    events: Array
  },
  methods: {
    getIconComponent(type) {
      if(type === 1)
        return 'ClipboardListIcon'
      if(type === 4)
        return 'AtSymbolIcon'
      if(type === 5)
        return 'ExclamationIcon'
      if(type === 6)
        return 'TruckIcon'
      if(type === 7)
        return 'TruckIcon'
      if(type === 9)
        return 'InformationCircleIcon'
    },

    getIconColor(type) {
      if(type === 5)
        return 'text-red-500'

      return 'text-gray-500'
    }
  }
}
</script>
