<template>
  <button v-if="as !== 'a'" type="button" :class="classes"><slot/></button>
  <a v-else :class="classes"><slot/></a>
</template>

<script>
export default {
  name: "ColorButton",
  props: {
    color: {
      type: String,
      default: 'blue'
    },
    type: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'md'
    },
    as: {
      type: String,
      default: 'button'
    }
  },
  computed: {
    classes() {
      let allClasses = { //this is needed no to purge these classes at build-time
        blue: 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-blue-700 bg-blue-100 hover:bg-blue-200 focus:ring-blue-500',
        purple: 'bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 text-purple-700 bg-purple-100 hover:bg-purple-200 focus:ring-purple-500',
        green: 'bg-green-600 hover:bg-green-700 focus:ring-green-500 text-green-700 bg-green-100 hover:bg-green-200 focus:ring-green-500',
        red: 'bg-red-600 hover:bg-red-700 focus:ring-red-500 text-red-700 bg-red-100 hover:bg-red-200 focus:ring-red-500',
        yellow: 'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500 text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:ring-yellow-500',
      }

      let base = 'text-center inline-flex items-center border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 '

      switch (this.size) {
        case 'xs':
          base += 'px-2.5 py-1.5 text-xs rounded '
          break;
        case 'sm':
          base += 'px-3 py-2 text-sm leading-4 rounded-md '
          break;
        case 'md':
          base += 'px-4 py-2 text-sm rounded-md '
          break;
        case 'lg':
          base += 'px-4 py-2 text-base rounded-md '
          break;
        case 'xl':
          base += 'px-6 py-3 text-base rounded-md '
          break;
      }

      if(this.type === 'primary' || this.type === 'white') {
        base += 'shadow-sm '
      }

      switch (this.type) {
        case 'primary':
          base += 'text-white bg-[[color]]-600 hover:bg-[[color]]-700 focus:ring-[[color]]-500'
          break
        case 'secondary':
          base += 'text-[[color]]-700 bg-[[color]]-100 hover:bg-[[color]]-200 focus:ring-[[color]]-500'
          break
        case 'white':
          base += 'text-gray-700 bg-white hover:bg-gray-50 focus:ring-[[color]]-500'
          break
      }

      return base.replaceAll('[[color]]', this.color)
    }
  }
}
</script>
