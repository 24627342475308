<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>

<script>
import RouterView from 'vue-router'
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";

export default {
  name: 'App',
  components: {
    RouterView, EmptyLayout, MainLayout
  },
  computed: {
    layout () {
      return (this.$route.meta.auth ? MainLayout : EmptyLayout )
    }
  },
}
</script>
