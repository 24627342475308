<template>
  <div :class="{ 'bg-blue-100 border-blue-600': this.is_selected }" class="flex py-1 border-b rounded-md bg-white hover:bg-blue-50">
    <div class="flex-none font-mono text-gray-500 px-2 py-1">#{{ orderObj.id }}</div>
    <div class="flex-1 px-2 py-1">
      <strong>{{ orderObj.shipping.name }}</strong> <span class="text-gray-600"> - {{ orderObj.shipping.tel }}<br>
      {{ orderObj.shipping.zip }} {{ orderObj.shipping.city }}, {{ orderObj.shipping.address }}</span>
    </div>
    <div v-if="orderObj.shipping_status !== 12" class="px-2 py-1 text-right">
      <badge :color="statusColor(orderObj.shipping_status)">{{ statusName(orderObj.shipping_status) }}</badge>
    </div>
    <div class="px-2 py-1 text-right">
      {{ orderObj.formatted_date }}
    </div>
  </div>
</template>

<script>
import ShippingStatus from "@/services/shippingStatus"
import {EventBus} from "@/event-bus"
import Badge from "@/components/Badge"

export default {
  name: 'OrderListItem',
  components: {
    Badge,
  },
  data() {
    return {
      orderObj: this.order
    }
  },
  props: {
    order: Object,
    is_selected: Boolean
  },
  mixins: [ShippingStatus],
  mounted() {
    let _this = this

    EventBus.$on('orderReloaded', function(order) {
      if(_this.order.id === order.id) {
        _this.orderObj = order
      }
    })
  }
}
</script>
