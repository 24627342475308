function setPrint() {
    this.contentWindow.__container__ = this
    this.contentWindow.onbeforeunload = closePrint
    this.contentWindow.onafterprint = closePrint
    this.contentWindow.focus() // Required for IE
    this.contentWindow.print()
}

function closePrint() {
    document.body.removeChild(this.__container__)
}

function printPage(sURL) {
    let oHideFrame = document.createElement("iframe");
    oHideFrame.onload = setPrint;
    oHideFrame.style.position = "fixed";
    oHideFrame.style.right = "0";
    oHideFrame.style.bottom = "0";
    oHideFrame.style.width = "0";
    oHideFrame.style.height = "0";
    oHideFrame.style.border = "0";
    oHideFrame.src = sURL;
    document.body.appendChild(oHideFrame);
}

export default printPage
