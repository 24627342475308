import {createWebHistory, createRouter} from "vue-router";

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Orders from '../pages/Orders';
import store from '../store'
import Packing from "@/pages/Packing";
import PackingIndex from "@/pages/PackingIndex";

export const routes = [
    {
        name: 'dashboard',
        path: '/',
        component: Dashboard,
        meta: { auth: true }
    },
    {
        name: 'orders',
        path: '/orders',
        component: Orders,
        meta: { auth: true }
    },
    {
        name: 'packing-index',
        path: '/packing-index',
        component: PackingIndex,
        meta: { auth: true }
    },
    {
        name: 'packing',
        path: '/packing/:batchId?',
        component: Packing,
        meta: { auth: true }
    },
    {
        name: 'login',
        path: '/login',
        component: Login
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

router.beforeEach((to) => {
    if (to.name !== 'login' && !store.state.isAuthenticated)
        return { name: 'login' }
})

export default router;