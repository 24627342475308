import axios from "axios";
import router from '../router';
import Cookies from 'js-cookie';

let api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
});

api.defaults.withCredentials = true

api.get('/sanctum/csrf-cookie')

api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
        router.push({name: 'login'})
    }

    return Promise.reject(error);
});

// Request interceptor. Runs before your request reaches the server
const onRequest = (config) => {
    // If http method is `post | put | delete` and XSRF-TOKEN cookie is
    // not present, call '/sanctum/csrf-cookie' to set CSRF token, then
    // proceed with the initial response
    if ((
            config.method == 'post' ||
            config.method == 'put' ||
            config.method == 'delete'
            /* other methods you want to add here */
        ) &&
        !Cookies.get('XSRF-TOKEN')) {
        return setCSRFToken()
            .then(response => config);
    }
    return config;
}

// A function that calls '/api/csrf-cookie' to set the CSRF cookies. The
// default is 'sanctum/csrf-cookie' but you can configure it to be anything.
const setCSRFToken = () => {
    return api.get('/sanctum/csrf-cookie'); // resolves to '/api/csrf-cookie'.
}

// attach your interceptor
api.interceptors.request.use(onRequest, null);


export default api;