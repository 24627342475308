const statuses = {
    10: {
        name: 'Várakozik',
        color: 'red-500',
    },
    20: {
        name: 'Beküldve',
        color: 'yellow-500',
    },
    30: {
        name: 'Nyomtatva',
        color: 'green-500',
    },
}

export default {
    methods: {
        parcelStatusName(id) {
            id = id.toString()
            if (id in statuses) {
                return statuses[id].name
            }

            return 'Ismeretlen'
        },

        parcelStatusColor(id) {
            id = id.toString()
            if (id in statuses) {
                return statuses[id].color
            }

            return 'red-500'
        }
    }
}