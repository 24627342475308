<template>
  <div class="p-4" v-if="loaded">
    <div class="flex mb-4">
      <div class="flex-1 text-center">
        <span class="font-mono text-xl">#{{ order.id }}</span>{{ ' ' }}
        <badge :color="statusColor(order.shipping_status)">{{ statusName(order.shipping_status) }}</badge>
      </div>

      <Menu as="div" class="relative inline-block text-left z-20">
        <div>
          <MenuButton
              class="inline-flex justify-center w-full rounded-full px-1 py-1 bg-gray-50 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
            <dots-vertical-icon class="w-6 h-6"></dots-vertical-icon>
          </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems
              class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div class="py-1">
              <div class="font-medium uppercase px-4 font text-xs text-gray-400 py-1">Állapot módosítás</div>
              <MenuItem v-if="! [16, 13].includes(order.shipping_status)">
                <a href="#" @click="setStatusPacking()"
                   class="text-gray-700 block px-4 py-2 hover:bg-gray-200 hover:text-gray-900">Összekészítés alatt</a>
              </MenuItem>
              <MenuItem v-if="! [16, 14].includes(order.shipping_status) && order.parcels">
                <a href="#" @click="setStatusPackedManual()"
                   class="text-gray-700 block px-4 py-2 hover:bg-gray-200 hover:text-gray-900">Csomagolva</a>
              </MenuItem>
              <MenuItem v-if="! [16, 15].includes(order.shipping_status)">
                <a href="#" @click="setStatusInTransit()"
                   class="text-gray-700 block px-4 py-2 hover:bg-gray-200 hover:text-gray-900">Futárnak átadva</a>
              </MenuItem>
            </div>
            <div class="py-1" v-if="! [10].includes(order.shipping_status)">
              <MenuItem>
                <a href="#" @click="setStatusParked()"
                   class="text-gray-700 block px-4 py-2 hover:bg-gray-200 hover:text-gray-900">Félretesz</a>
              </MenuItem>
            </div>
            <div class="py-1">
              <MenuItem v-if="! [17].includes(order.shipping_status)">
                <a href="#" @click="setStatusReturned()"
                   class="text-yellow-500 block px-4 py-2 hover:bg-yellow-100 hover:text-yellow-600">Visszáru</a>
              </MenuItem>
              <MenuItem v-if="! [1, 11, 12].includes(order.shipping_status)">
                <a href="#" @click="reset()" class="text-red-700 block px-4 py-2 hover:bg-red-100 hover:text-red-900">Visszaállítás</a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>

    </div>
    <p class="mb-4">
      <strong>{{ order.shipping.name }}</strong> <span class="text-gray-600"> - {{ order.shipping.tel }}<br>
      {{ order.shipping.zip }} {{ order.shipping.city }}<br>{{ order.shipping.address }}</span>
    </p>

    <template v-if="order.shipping_status === 12 || order.shipping_status === 13">
      <div class="flex mb-4">
        <input type="number" v-model="numberOfParcels" min="1" max="8"
               class="w-16 text-center border-r-0 rounded-r-none">
        <color-button @click="setStatusPacked()" class="rounded-l-none flex-1"> dobozba csomagolva</color-button>
      </div>
    </template>
    <template v-if="order.parcels">
      <div v-for="parcel in order.parcels" :key="parcel.id" class="flex bg-gray-100 rounded-lg p-2 mb-2 z-10">
        <div class="mr-2">
          <cube-icon class="w-8 h-8 text-gray-400"/>
        </div>
        <div class="flex-1">
          <a :href="parcel.label_url" target="_blank" class="text-blue-500 font-bold block">{{ parcel.parcel_id }} <span class="text-sm text-gray-500 font-normal">({{ order.batch_id }}.pdf)</span></a>
          <p>
            <badge :color="parcelStatusColor(parcel.status)">{{ parcelStatusName(parcel.status) }}</badge>
            {{ ' ' }}<span class="text-sm text-gray-600">{{ parcel.last_change_formatted }}</span>
          </p>
          <p class="text-sm text-gray-600">{{ parcel.status_message }}</p>
        </div>
      </div>
    </template>

    <div class="mb-4"></div>

    <TabGroup as="div" :defaultIndex="tabIndex" @change="changedTab">
      <TabList class="flex p-1 space-x-1 bg-gray-100 rounded-xl">
        <Tab as="template" v-slot="{ selected }">
          <button
              :class="[
              'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
              'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
              selected
                ? 'bg-white shadow'
                : 'text-blue-700 hover:bg-white',
            ]"
          >
            Termékek
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
              :class="[
              'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
              'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
              selected
                ? 'bg-white shadow'
                : 'text-blue-700 hover:bg-white',
            ]"
          >
            Történet
          </button>
        </Tab>
      </TabList>

      <TabPanels class="mt-2">
        <TabPanel>
          <div class="mb-2">
            <ordered-product-list-item v-for="op in order.ordered_products" :op="op"
                                       :key="op.id"></ordered-product-list-item>
          </div>
          <div class="grid grid-cols-2 gap-2">
            <div class="bg-gray-100 rounded-md text-lg text-right px-2 py-1">
              {{ order.weight_gross / 1000 }} kg
            </div>
            <div class="bg-gray-100 rounded-md text-lg text-right px-2 py-1">
              {{ sumAmount }} db
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <order-event-list :events="order.events"></order-event-list>
        </TabPanel>
      </TabPanels>
    </TabGroup>


  </div>
  <div class="pt-12 text-center" v-else>
    <loading-icon class="animate-spin h-12 w-12 mx-auto text-blue-500" v-if="!firstStart"></loading-icon>
  </div>
</template>

<script>
import order from "@/api/order"
import OrderedProductListItem from "@/components/OrderedProductListItem"
import LoadingIcon from "@/components/LoadingIcon"
import {TabGroup, TabList, Tab, TabPanels, TabPanel, Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import ColorButton from "@/components/ColorButton"
import {EventBus} from "@/event-bus"
import store from "../store"
import OrderEventList from "@/components/OrderEventList"
import OrderService from "@/services/orderservice"
import {CubeIcon, DotsVerticalIcon} from '@heroicons/vue/outline'
import ParcelStatus from '@/services/parcelStatus'
import ShippingStatus from "@/services/shippingStatus"
import Badge from "@/components/Badge"

export default {
  name: 'OrderDetails',
  components: {
    Badge,
    OrderEventList,
    ColorButton,
    LoadingIcon,
    OrderedProductListItem,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    CubeIcon,
    DotsVerticalIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
  },
  data() {
    return {
      firstStart: true,
      loaded: false,
      numberOfParcels: 1,
      order: {},
      tabIndex: 0
    }
  },
  props: {
    orderId: Number,
    active: {
      type: String,
      default: 'products'
    }
  },
  mixins: [ParcelStatus, ShippingStatus],
  mounted() {
    this.tabIndex = this.active === 'products' ? 0 : 1
    let _this = this

    EventBus.$on('orderReloaded', function (order) {
      if (_this.orderId === order.id) {
        _this.order = order
      }
    })
  },
  beforeUnmount() {
    EventBus.$off('orderReloaded')
  },
  computed: {
    sumAmount() {
      let sum = 0;
      for (let i in this.order.ordered_products) {
        let op = this.order.ordered_products[i]
        sum += op.amount
      }
      return sum;
    }
  },
  watch: {
    // whenever question changes, this function will run
    orderId(newId, oldId) {
      this.fetchOrderInfo();
    },
    numberOfParcels(newValue, oldValue) {
      store.setParcelCount(this.orderId, newValue);
    },
  },
  methods: {
    fetchOrderInfo() {
      this.firstStart = false;
      this.loaded = false

      order.show(this.orderId).then(response => {
        if (response.status === 200) {
          this.order = response.data.data
          this.numberOfParcels = store.getParcelCount(this.orderId)
          this.loaded = true
        } else {
          console.error(response)
        }
      })
          .catch(function (error) {
            console.error(error)
          })
    },
    changedTab(index) {
      this.tabIndex = index
    },
    setStatusPacked() {
      this.loaded = false

      let _this = this

      order.shippingPacked(this.orderId, this.numberOfParcels).then(response => {
        if (response.status === 200) {
          OrderService.reloadOrder(this.orderId).then(function (order) {
            _this.numberOfParcels = 1
            _this.loaded = true
          });
        } else {
          console.error(response)
        }
      })
          .catch(function (error) {
            console.error(error)
          })
    },
    setStatusPacking() {
      this.loaded = false

      let _this = this

      order.shippingPacking(this.orderId)
          .then(response => {
            if (response.status === 200) {
              OrderService.reloadOrder(this.orderId).then(function (order) {
                _this.loaded = true
              });
            } else {
              console.error(response)
            }
          })
          .catch(function (error) {
            console.error(error)
          })
    },
    setStatusPackedManual() {
      this.loaded = false

      let _this = this

      order.shippingPackedManual(this.orderId)
          .then(response => {
            if (response.status === 200) {
              OrderService.reloadOrder(this.orderId).then(function (order) {
                _this.loaded = true
              });
            } else {
              console.error(response)
            }
          })
          .catch(function (error) {
            console.error(error)
          })
    },
    setStatusInTransit() {
      this.loaded = false

      let _this = this

      order.shippingInTransit(this.orderId)
          .then(response => {
            if (response.status === 200) {
              OrderService.reloadOrder(this.orderId).then(function (order) {
                _this.loaded = true
              });
            } else {
              console.error(response)
            }
          })
          .catch(function (error) {
            console.error(error)
          })
    },
    setStatusParked() {
      this.loaded = false

      let _this = this

      order.shippingParked(this.orderId)
          .then(response => {
            if (response.status === 200) {
              OrderService.reloadOrder(this.orderId).then(function (order) {
                _this.loaded = true
              });
            } else {
              console.error(response)
            }
          })
          .catch(function (error) {
            console.error(error)
          })
    },
    setStatusReturned() {
      this.loaded = false

      let _this = this

      order.shippingReturned(this.orderId)
          .then(response => {
            if (response.status === 200) {
              OrderService.reloadOrder(this.orderId).then(function (order) {
                _this.loaded = true
              });
            } else {
              console.error(response)
            }
          })
          .catch(function (error) {
            console.error(error)
          })
    },
    reset() {
      this.loaded = false

      let _this = this

      order.shippingReset(this.orderId)
          .then(response => {
            if (response.status === 200) {
              OrderService.reloadOrder(this.orderId).then(function (order) {
                _this.loaded = true
              });
            } else {
              console.error(response)
            }
          })
          .catch(function (error) {
            console.error(error)
          })
    }
  }
}
</script>
