<template>
  <div class="">

  </div>
</template>

<script>
import ColorButton from "@/components/ColorButton";
export default {
  name: "Dashboard",
  //components: {ColorButton}
}
</script>

<style scoped>

</style>
