<template>
  <div class="bg-gradient-to-r from-blue-500 to-blue-800 min-h-screen">

    <div class="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div class="max-w-md w-full">
        <div>
          <CubeIcon class="h-24 w-24 mx-auto text-white" aria-hidden="true"/>
          <h2 class="mt-4 text-center text-3xl font-extrabold text-white">
            BAKKER Csomagok
          </h2>
          <p class="mt-2 text-center text-sm text-gray-200">
            Bejelentkezés
          </p>
        </div>

        <div class="bg-gray-50 rounded-2xl shadow-xl p-6 mt-8">
          <form class="space-y-6">
            <div class="rounded-md shadow-sm -space-y-px">
              <div>
                <label for="email-address" class="sr-only">Email address</label>
                <input id="email-address" name="email" type="email" autocomplete="email" required autofocus
                       v-model="email"
                       class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                       placeholder="E-mail cím"/>
              </div>
              <div>
                <label for="password" class="sr-only">Password</label>
                <input id="password" name="password" type="password" autocomplete="current-password" required
                       v-model="password"
                       class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                       placeholder="Jelszó"/>
              </div>
            </div>

            <div>
              <button type="submit" @click="handleSubmit"
                      class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true"/>
            </span>
                Bejelentkezés
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from "../api/user"
import store from "../store"

import {LockClosedIcon} from '@heroicons/vue/solid'
import {CubeIcon} from '@heroicons/vue/outline'

export default {
  components: {
    LockClosedIcon,
    CubeIcon
  },

  data() {
    return {
      email: "",
      password: "",
      error: null
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      if (this.password.length > 0) {
        user.login({
          email: this.email,
          password: this.password
        })
          .then(response => {
            if (response.status == 204) {
              store.state.isAuthenticated = true;
              this.$router.push({name: 'dashboard'})
            } else {
              this.error = 'Unknown error'
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    }
  },
}
</script>

<style scoped>

</style>