const statuses = {
    1: {
        name: 'Új',
        color: 'red-500',
    },
    11: {
        name: 'Jóváhagyásra vár',
        color: 'red-500',
    },
    12: {
        name: 'Jóváhagyva',
        color: 'blue-500',
    },
    13: {
        name: 'Összekészítés alatt',
        color: 'blue-500',
    },
    14: {
        name: 'Csomagolva',
        color: 'purple-500',
    },
    15: {
        name: 'Futárnak átadva',
        color: 'green-500',
    },
    16: {
        name: 'Kiszállítva',
        color: 'green-500',
    },
    17: {
        name: 'Visszáru',
        color: 'pink-500',
    },
    18: {
        name: 'Sérült',
        color: 'red-500',
    },
    10: {
        name: 'Félretéve',
        color: 'yellow-600',
    },
    9: {
        name: 'Hibás',
        color: 'red-500',
    },
}

export default {
    methods: {
        statusName(id) {
            id = id.toString()
            if (id in statuses) {
                return statuses[id].name
            }

            return 'Ismeretlen'
        },

        statusColor(id) {
            let allClasses = [ //this is needed no to purge these classes at build-time
                'bg-red-500 bg-blue-500 bg-purple-500 bg-green-500 bg-gray-500 bg-pink-500 bg-yellow-600 bg-gray-600',
            ]

            id = id.toString()
            if (id in statuses) {
                return statuses[id].color
            }

            return 'red-500'
        }
    }
}