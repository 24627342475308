<template>
  <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
    <div class="-mt-px w-0 flex-1 flex">
      <button @click="changePage(currentPage-1)" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
        <ArrowNarrowLeftIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        Előző
      </button>
    </div>
    <div class="hidden md:-mt-px md:flex">
      <button v-for="(pageNumber, index) in pageNumbers" :key="index" @click="changePage(pageNumber)" :class="[pageNumber === currentPage ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium']">
        {{ pageNumber === 0 ? '...' : pageNumber }}
      </button>
    </div>
    <div class="-mt-px w-0 flex-1 flex justify-end">
      <button @click="changePage(currentPage+1)" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
        Következő
        <ArrowNarrowRightIcon class="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
      </button>
    </div>
  </nav>
</template>

<script>
import {ArrowNarrowLeftIcon, ArrowNarrowRightIcon} from "@heroicons/vue/solid";

export default {
  name: "Pager",
  components: {ArrowNarrowLeftIcon, ArrowNarrowRightIcon},
  data() {
    return {
      hasPageChanged: false,
      pageNumber: 1
    }
  },
  props: {
    /**
     current_page: 1
     from: 1
     last_page: 32
     path: "https://bodyselectlan.com/wh-api/orders"
     per_page: 25
     to: 25
     total: 796
     */
    meta: {
      type: Object,
    },
  },
  mounted() {

  },
  computed: {
    currentPage() {
      if(this.hasPageChanged || !this.meta.current_page) {
        return this.pageNumber
      }

      return this.meta.current_page
    },
    pageNumbers() {
      let pages = [1]

      if(this.currentPage - 2 > 2) {
        pages.push(0)
      }

      for (let i=-2; i<=2; i++) {
        let page = this.currentPage + i

        if(page > 1 && page < this.meta.last_page) {
          pages.push(page)
        }
      }

      if(this.currentPage + 2 < this.meta.last_page - 1) {
        pages.push(0)
      }

      pages.push(this.meta.last_page)

      return pages
    }
  },
  methods: {
    changePage(number) {
      if(number > 0 && number <= this.meta.last_page) {
        this.hasPageChanged = true
        this.pageNumber = number
        this.$emit('changePage', number)
      }
    }
  }
}
</script>
