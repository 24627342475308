import { reactive } from 'vue'

const store = {
    debug: true,

    state: reactive({
        message: 'Hello!',
        isAuthenticated: true,
        selectedProduct: 0,
        lastBatchId: 0,
        parcelCounts: {}
    }),

    setMessageAction(newValue) {
        if (this.debug) {
            console.log('setMessageAction triggered with', newValue)
        }

        this.state.message = newValue
    },

    clearMessageAction() {
        if (this.debug) {
            console.log('clearMessageAction triggered')
        }

        this.state.message = ''
    },

    setParcelCount(orderId, count) {
        let key = 'o' + orderId;
        this.state.parcelCounts[key] = count;
    },

    getParcelCount(orderId) {
        let key = 'o' + orderId;
        let counts = this.state.parcelCounts;

        if(Object.prototype.hasOwnProperty.call(counts, key)) {
            return counts[key];
        }

        return 1;
    }
}

export default store