<template>
  <div class="flex-1 flex items-stretch overflow-hidden">
    <main class="flex-1 overflow-y-auto">
      <!-- Primary column -->
      <section class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
        <div class="p-4">

          <div class="mb-4 flex justify-between">
            <h1 class="text-3xl font-bold">Csomagolás: {{ batchId }}</h1>
            <div class="">
              <color-button class="ml-1" type="secondary" color="red">Visszaállítás</color-button>
              <color-button class="ml-1" @click="sendPrint">Összesítő nyomtatás</color-button>
            </div>
          </div>

          <div class="pt-12 text-center" v-if="loading">
            <loading-icon class="animate-spin h-12 w-12 mx-auto text-blue-500"></loading-icon>
          </div>
          <div v-else>
            <div class="mb-4">
              <color-button type="secondary" class="mr-1" @click="batchPacked">Mind csomagolva</color-button>
              <color-button as="a" :href="batchPrintUrl" type="secondary" class="mr-1" target="_blank">Összes címke nyomtatása</color-button>
            </div>

            <div class="mb-8">

              <Disclosure v-slot="{ open }">
                <DisclosureButton class="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                  <span>Összesítő lista (a még nem csomagolt rendelésekhez)</span>

                  <ChevronUpIcon
                      :class="open ? 'transform rotate-180' : ''"
                      class="w-5 h-5 text-blue-500"
                  />
                </DisclosureButton>
                <DisclosurePanel class="p-2 bg-white rounded-lg overflow-hidden">
                  <ordered-product-list-item v-for="op in grouped" :op="op" :key="op.id" :small="true"></ordered-product-list-item>
                </DisclosurePanel>
              </Disclosure>

            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-5 gap-4">
              <order-card v-for="order in orders" :key="order.id" :order="order" @click="selectedId = order.id" :is_selected="selectedId === order.id"></order-card>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- Secondary column (hidden on smaller screens) -->
    <aside class="hidden w-96 bg-white border-l border-gray-200 overflow-y-auto lg:block">
      <order-details :order-id="selectedId" :active="'events'"></order-details>
    </aside>
  </div>
</template>

<script>

import packing from "@/api/packing";
import orderApi from "@/api/order";
import sendPrint from "@/services/printer";
import OrderDetails from "@/components/OrderDetails";
import OrderCard from "@/components/OrderCard";
import OrderedProductListItem from "@/components/OrderedProductListItem";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue'
import {ChevronUpIcon} from "@heroicons/vue/solid";
import ColorButton from "@/components/ColorButton";
import store from "../store"
import LoadingIcon from "@/components/LoadingIcon";

export default {
  name: "Packing",
  components: {
    LoadingIcon,
    ColorButton,
    OrderDetails, OrderCard, OrderedProductListItem, Disclosure, DisclosureButton, DisclosurePanel, ChevronUpIcon},
  data() {
    return {
      loading: true,
      open: false,
      batchId: null,
      orders: [],
      grouped: [],
      selectedId: 0
    }
  },
  mounted() {
    this.batchId = this.$route.params.batchId
    store.state.lastBatchId = this.batchId
    this.loadOrders()
  },
  computed: {
    batchPrintUrl() {
      return process.env.VUE_APP_API_BASE_URL + 'wh-api/print/parcel-label/batch/' + this.batchId
    }
  },
  methods: {
    loadOrders() {
      this.loading = true
      packing.packingList(this.batchId, this.$route.query.amount).then(response => {
        if (response.status === 200) {
          this.orders = response.data.orders
          this.grouped = response.data.grouped
          this.batchId = response.data.batchId
        } else {
          console.error(response)
        }

        this.loading = false
      })
          .catch(function (error) {
            console.error(error)
            this.loading = false
          })
    },
    sendPrint() {
      sendPrint(process.env.VUE_APP_API_BASE_URL + 'wh-api/print/batch/' + this.batchId)
    },
    batchPacked() {
      this.loading = true

      let data = [];
      this.orders.forEach((order) => {
        data.push([order.id, store.getParcelCount(order.id)])
      });

      this.orders = []
      this.grouped = []
      this.selectedId = 0

      orderApi.batchPacked(this.batchId, {pc: data}).then(response => {
        if (response.status === 200) {
          this.loadOrders()
        } else {
          console.error(response)
        }
      })
          .catch(function (error) {
            console.error(error)
            this.loading = false
          })
    }
  }
}
</script>

<style scoped>

</style>