<template>
  <div>
    <div :class="{ 'border-blue-600 shadow-2xl': this.is_selected }" class="bg-white rounded-lg border border-white shadow-lg overflow-hidden">
      <div :class="{ 'bg-blue-100': this.is_selected }" class="bg-gray-100 py-2 px-4 flex justify-between">
        <div>
          <span class="font-mono text-xl">{{ cardTitle }}</span>{{ ' ' }}
          <badge v-if="orderObj.shipping_status !== 13" :color="statusColor(orderObj.shipping_status)">{{ statusName(orderObj.shipping_status) }}</badge>
        </div>
        <div v-if="order.shipping_status === 12 || order.shipping_status === 13" class="relative pr-4">
          {{ parcelCount }}
          <div class="absolute -top-1 -right-2">
            <ChevronUpIcon @click="pcUp" class="text-gray-400 w-5 h-4 hover:bg-gray-300 cursor-pointer" />
            <ChevronUpIcon @click="pcDown" class="text-gray-400 w-5 h-4 hover:bg-gray-300 cursor-pointer transform rotate-180 " />
          </div>
        </div>
      </div>
      <div class="mb-2">
        <ordered-product-list-item v-for="op in orderObj.ordered_products" :op="op" :key="op.id"></ordered-product-list-item>
      </div>
    </div>
  </div>
</template>

<script>
import OrderedProductListItem from "@/components/OrderedProductListItem"
import ShippingStatus from "@/services/shippingStatus"
import {EventBus} from "@/event-bus"
import Badge from "@/components/Badge"
import store from "../store"
import {ChevronUpIcon} from "@heroicons/vue/solid"

export default {
  name: 'OrderCard',
  components: {Badge, OrderedProductListItem, ChevronUpIcon},
  data() {
    return {
      orderObj: this.order,
    }
  },
  props: {
    order: Object,
    modelValue: Number,
    is_selected: Boolean
  },
  mixins: [ShippingStatus],
  mounted() {
    let _this = this

    store.setParcelCount(_this.order.id, store.getParcelCount(_this.order.id));

    EventBus.$on('orderReloaded', function(order) {
      if(_this.order.id === order.id) {
        _this.orderObj = order
      }
    })
  },
  beforeUnmount() {
    EventBus.$off('orderReloaded')
  },
  computed: {
    sumAmount() {
      let sum = 0;
      for(let i in this.order.ordered_products) {
        let op = this.order.ordered_products[i]
        sum += op.amount
      }
      return sum;
    },
    cardTitle() {
      return '#' + this.order.id + '-' + this.order.shipping.name.split(' ')[0].substr(0,5);
    },
    parcelCount() {
      return store.getParcelCount(this.order.id);
    }
  },
  methods: {
    pcUp() {
      let count = store.getParcelCount(this.order.id) + 1;
      store.setParcelCount(this.order.id, Math.min(8, count));
    },
    pcDown() {
      let count = store.getParcelCount(this.order.id) - 1;
      store.setParcelCount(this.order.id, Math.max(1, count));
    }
  }
}
</script>
