<template>
  <div class="flex-1 flex items-stretch overflow-hidden">
    <main class="flex-1 overflow-y-auto">
      <!-- Primary column -->
      <section class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
        <div class="p-4">
          <OrderListItem v-for="order in orders" :key="order.id" :order="order" @click="selectedId = order.id" :is_selected="selectedId === order.id"></OrderListItem>
        </div>
        <div v-if="paging" class="px-4 pb-12">
          <pager :meta="paging" @changePage="onChangePage"></pager>
        </div>
      </section>
    </main>

    <!-- Secondary column (hidden on smaller screens) -->
    <aside class="hidden w-96 bg-white border-l border-gray-200 overflow-y-auto lg:block">
      <order-details :order-id="selectedId"></order-details>
    </aside>
  </div>
</template>

<script>

import order from "@/api/order";
import store from "@/store";
import OrderListItem from "@/components/OrderListItem";
import OrderDetails from "@/components/OrderDetails";
import Pager from "@/components/Pager";

export default {
  name: "Orders",
  components: {Pager, OrderDetails, OrderListItem},
  data() {
    return {
      orders: [],
      filters: {},
      selectedId: 0,
      paging: {}
    }
  },
  mounted() {
    this.filters = this.$route.query
    this.reloadList()
  },
  methods: {
    reloadList() {
      order.list(this.filters).then(response => {
        if (response.status === 200) {
          this.orders = response.data.data
          this.paging = response.data.meta ? response.data.meta : false
        } else {
          console.error(response)
        }
      })
          .catch(function (error) {
            console.error(error)
          })
    },
    onChangePage(number) {
      console.log(number)
      this.filters.page = number
      this.reloadList()
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.filters = to.query
    this.reloadList()
    next()
  }
}
</script>

<style scoped>

</style>