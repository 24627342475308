<template>
  <div class="flex-1 flex items-stretch overflow-hidden">
    <main class="flex-1 overflow-y-auto">
      <!-- Primary column -->
      <section class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
        <div class="p-4">

          <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
            <div class="relative bg-white pt-5 px-4 pb-16 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
              <dt>
                <div class="absolute bg-blue-500 rounded-md p-3">
                  <collection-icon class="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p class="ml-16 text-sm font-medium text-gray-500 truncate">Várakozó rendelések</p>
              </dt>
              <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
                <p class="text-2xl font-semibold text-gray-900">
                  {{ availableCount }}
                </p>
                <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                  <div class="flex">
                    <input type="number" v-model="packingAmount" min="0" max="18" class="w-16 mr-4 text-center">
                    <color-button type="white" @click="startNew">Új összekészítés</color-button>
                  </div>
                </div>
              </dd>
            </div>

            <div class="relative bg-white pt-5 px-4 pb-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
              <dt>
                <div class="absolute bg-blue-500 rounded-md p-3">
                  <refresh-icon class="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p class="ml-16 text-sm font-medium text-gray-500 truncate">Megkezdett összekészítések</p>
              </dt>
              <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
                <p class="text-2xl font-semibold text-gray-900">
                  {{ inProgressCount }}
                </p>
                <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-9 sm:px-6">

                </div>
              </dd>
            </div>

            <div class="relative bg-white pt-5 px-4 pb-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
              <dt>
                <div class="absolute bg-blue-500 rounded-md p-3">
                  <exclamation-icon class="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p class="ml-16 text-sm font-medium text-gray-500 truncate">Félretéve</p>
              </dt>
              <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
                <p class="text-2xl font-semibold text-gray-900">
                  {{ parkedCount }}
                </p>
                <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-9 sm:px-6">

                </div>
              </dd>
            </div>

            <div class="relative bg-white pt-5 px-4 pb-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
              <dt>
                <div class="absolute bg-blue-500 rounded-md p-3">
                  <clipboard-check-icon class="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p class="ml-16 text-sm font-medium text-gray-500 truncate">Futárra vár</p>
              </dt>
              <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
                <p class="text-2xl font-semibold text-gray-900">
                  {{ packedCount }}
                </p>
                <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                  <color-button type="secondary" @click="setInTransit()">Mind átadása a futárnak</color-button>
                </div>
              </dd>
            </div>
          </dl>

          <h2 class="text-lg leading-6 font-medium text-gray-900 border-b mt-8 mb-4">
            Megkezdett összekészítések
          </h2>

          <div class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <div v-for="(orders, batchId) in inProgress" :key="batchId" class="bg-white rounded-lg shadow-md overflow-hidden">
              <div class="relative bg-gray-100 text-center font-mono text-xl py-2" :class="{'bg-yellow-100': batchId === state.lastBatchId}">
                {{ batchId }}
                <div class="absolute top-1.5 right-2">
                  <router-link :to="{name: 'packing', params: {batchId: batchId}}" class="rounded-full bg-blue-200 text-blue-500 block"><view-grid-icon class="w-8 h-8 p-1"></view-grid-icon></router-link>
                </div>
              </div>
              <div v-for="order in orders" :key="order.id" :class="{'bg-blue-200': selectedId === order.id}" class="py-1 text-center border-b font-mono" @click="selectedId = order.id">
                {{ orderTitle(order) }}
              </div>
            </div>
          </div>

          <h2 class="text-lg leading-6 font-medium text-gray-900 border-b mt-8 mb-4">
            Félretéve
          </h2>

          <OrderListItem v-for="order in parked" :key="order.id" :order="order" @click="selectedId = order.id" :is_selected="selectedId === order.id"></OrderListItem>

        </div>
      </section>
    </main>

    <!-- Secondary column (hidden on smaller screens) -->
    <aside class="hidden w-96 bg-white border-l border-gray-200 overflow-y-auto lg:block">
      <order-details :order-id="selectedId"></order-details>
    </aside>
  </div>
</template>

<script>

import packing from "@/api/packing"
import OrderDetails from "@/components/OrderDetails"
import {CollectionIcon} from "@heroicons/vue/solid"
import ColorButton from "@/components/ColorButton"
import {ViewGridIcon, RefreshIcon, ExclamationIcon, ClipboardCheckIcon} from "@heroicons/vue/outline"
import store from "../store"
import OrderListItem from "@/components/OrderListItem";
import {EventBus} from "@/event-bus";

export default {
  name: "PackingIndex",
  components: {
    OrderListItem,
    ColorButton,
    OrderDetails,
    CollectionIcon,
    ViewGridIcon,
    RefreshIcon,
    ExclamationIcon,
    ClipboardCheckIcon
  },
  data() {
    return {
      inProgress: [],
      parked: [],
      availableCount: 0,
      inProgressCount: 0,
      parkedCount: 0,
      packedCount: 0,

      selectedId: 0,
      packingAmount: 6,
      state: store.state
    }
  },
  mounted() {
    this.loadData()

    let _this = this

    EventBus.$on('orderReloaded', function(order) {
      _this.loadData()
    })
  },
  computed: {
  },
  methods: {
    loadData() {
      packing.index().then(response => {
        if (response.status === 200) {
          this.inProgress = response.data.inProgress
          this.parked = response.data.parked
          this.availableCount = response.data.availableCount
          this.inProgressCount = response.data.inProgressCount
          this.parkedCount = response.data.parkedCount
          this.packedCount = response.data.packedCount
        } else {
          console.error(response)
        }
      })
          .catch(function (error) {
            console.error(error)
          })
    },

    startNew() {
      this.$router.push({name: 'packing', params: {amount: this.packingAmount}})
    },

    setInTransit() {
      packing.setInTransit().then(response => {
        if (response.status === 200) {
          this.loadData()
        } else {
          console.error(response)
        }
      })
          .catch(function (error) {
            console.error(error)
          })
    },

    orderTitle(order) {
      return '#' + order.id + '-' + order.shipping.name.split(' ')[0].substr(0,5);
    }
  }
}
</script>

<style scoped>

</style>