import api from "./api"

export default {
    async list(filter) {
        return api.get("wh-api/orders", { params: filter })
    },
    async show(id) {
        return api.get("wh-api/orders/" + id)
    },
    async shippingPacking(id) {
        return api.put("wh-api/orders/" + id + "/shipping-packing")
    },
    async shippingPacked(id, parcelCount) {
        return api.put("wh-api/orders/" + id + "/shipping-packed?parcel_count=" + parcelCount)
    },
    async shippingPackedManual(id) {
        return api.put("wh-api/orders/" + id + "/shipping-packed-manual")
    },
    async shippingParked(id) {
        return api.put("wh-api/orders/" + id + "/shipping-parked")
    },
    async shippingInTransit(id) {
        return api.put("wh-api/orders/" + id + "/shipping-in-transit")
    },
    async shippingReturned(id) {
        return api.put("wh-api/orders/" + id + "/shipping-returned")
    },
    async shippingReset(id) {
        return api.put("wh-api/orders/" + id + "/shipping-reset")
    },

    async batchPacked(batchId, data) {
        return api.put("wh-api/orders/" + batchId + "/batch-packed", data)
    },
}
